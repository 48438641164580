import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "The Down and Dirty: After the Resume",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "the-down-and-dirty-after-the-resume",
  "draft": false,
  "meta_title": "The Down and Dirty: After the Resume"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You can’t launch a job search without a great resume, but now that you’ve got a great one, you have to strategize about the next steps in your search. This process is oddly similar to dating: If you know what you’re looking for, you’re more likely to find it – target selectively! It also doesn’t hurt to have some great connections or wingmen in both of these processes so – network, network, network!`}</p>
    <p><strong parentName="p">{`Targeting Selectively:`}</strong><br parentName="p"></br>{`
`}{`Scattershot is never the most effective way to conduct your job search. You’ll only exhaust yourself and get discouraged if you send tons of resumes to tons of employers. If you just randomly blanket the market with resumes, you’re going to get more rejections – no matter how awesome your resume is – because employers are wise to that kind of mass marketing. Think about those mass mail marketing pieces you find in your snail mail or those spam emails you get in your inbox. You likely don’t even open them. Employers won’t read past the first few lines if you are unfocused. So focus in on your true target, and your search will be more effective and less draining. Being targeted also comes across as more genuine and enthusiastic to potential employers. Think quality vs. quantity.`}</p>
    <p><strong parentName="p">{`Consider your VIPS:`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`Values`}</strong>{` – We all have them, but we don’t always consider them in our job search. What’s most important to you in this life? Has this changed over time? How do you want to represent your values in your work?`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Interests`}</strong>{` – What do you do in your free time? What makes you lose track of time you’re so interested in it? You love it so much, you’d do it for free if you could afford to.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Personality`}</strong>{` – Are you introverted or extroverted? Highly structured or a free-spirit? Do you possess the power of 'WOO' (Winning Others Over)?`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Skills`}</strong>{` – We often know the most about this aspect of ourselves, but it’s not the only thing we should consider. Your skills are broader than just your job titles and duties. You likely have more skills than you even realize.`}</p>
    <p>{`If you want assistance clarifying any of your VIPS, commonly used assessments available are: `}<a parentName="p" {...{
        "href": "https://www.sokanu.com/"
      }}>{`Sokanu`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.mbtionline.com/TaketheMBTI"
      }}>{`Myers-Briggs (MBTI)`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.myplan.com/assess/values.php"
      }}>{`MyPlan`}</a>{`, and `}<a parentName="p" {...{
        "href": "http://www.strengthsfinder.com/home.aspx"
      }}>{`Strengths Finder`}</a>{` are just the tip of the iceberg. Often the assessments are available online, but avoid anything with a fee.`}</p>
    <p>{`There are also many books available that coordinate with these assessments, if the printed page is more your style. `}<em parentName="p">{`Do What You Are`}</em>{` and `}<em parentName="p">{`Strengths-Based Leadership`}</em>{` are a couple great ones!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      